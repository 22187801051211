@charset "UTF-8";
/*
Theme Name: ghy
Theme URI: ‘’
Author: 王静
Description: 公司自定义主题
Version: 1.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variables
# Normalize
# Typography
	## Headings
	## Copy
# Elements
	## Lists
	## Tables
# Forms
	## Buttons
	## Fields
# Navigation
	## Links
	## Menus
	## Next & Previous
# Accessibility
# Alignments
# Clearings
# Layout
# Widgets
# Content
	## Archives
	## Posts and pages
	## Comments
# Blocks
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
/* Normalize */
/* Typography */
/* Elements */
/* Forms */
/* Navigation */
/* Accessibility */
/* Alignments */
/* Clearings */
/* Layout */
/* Content */
/* Widgets */
/* Blocks */
/* Media */
/* ghy style */
.header {
  height: 64px;
  width: 100%;
  background: #FFFFFF;
}

#header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background: #FFFFFF;
}

#header .logon-img {
  display: flex;
  align-items: center;
}

#header .pc-menu {
  display: block;
  flex: 1;
  display: flex;
}

#header .pc-menu .menu {
  flex: 1;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
}

#header .pc-menu .menu .menu-nav-menu-container #menu-nav-menu {
  padding: 0px;
}

#header .pc-menu .menu .menu-nav-menu-container .menu > .menu-item {
  margin-left: 60px;
  position: relative;
}

#header .pc-menu .menu .menu-nav-menu-container .menu > .menu-item:hover .sub-menu {
  display: block;
}

#header .pc-menu .menu .menu-nav-menu-container .menu a {
  text-decoration: none;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  padding-bottom: 6px;
  border-bottom: 2px solid transparent;
}

#header .pc-menu .menu .menu-nav-menu-container .menu .sub-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 30px;
  width: 160px;
  height: auto;
  padding: 0;
  line-height: 32px;
  color: #fff;
  background: #FFFFFF;
  border-radius: 0;
  padding: 20px;
  overflow: hidden;
  z-index: 100;
  box-sizing: border-box;
  transition: top 0.3s ease-out, opacity 0.3s ease-out;
}

#header .menu a:hover,
#header .current-menu-parent > a,
#header .current-menu-item a {
  color: #106FDD !important;
  border-bottom-color: #106FDD !important;
}

#header .user-content .logon {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
}

#header .user-content .register {
  width: 96px;
  background: linear-gradient(157deg, #1FC6FC 0%, #116EDD 100%);
  border-radius: 17px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  position: relative;
  top: -3px;
}

#header .user-content .register:hover,
#header .user-content .register:active {
  color: #FFFFFF;
  background: linear-gradient(157deg, #1FC6FC 0%, #116EDD 100%);
  outline: 0;
}

.icon-menu {
  display: none;
}

.app-menu {
  display: none;
}

@media screen and (max-width: 992px) {
  .pc-menu {
    display: none !important;
  }
  .app-menu {
    display: none;
    position: absolute;
    right: 0px;
    top: 64px;
    width: 100%;
    height: calc(100vh - 64px);
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    box-sizing: border-box;
  }
  .app-menu .menu-item {
    min-height: 50px;
    line-height: 50px;
    font-size: 20px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .app-menu .menu-item .sub-menu {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .app-menu .menu-item .sub-menu .menu-item:nth-last-child(1) {
    border-color: transparent;
  }
  .app-menu .menu-item a {
    color: #FFFFFF;
  }
  .icon-menu {
    display: block;
    position: absolute;
    width: 30px;
    height: 23px;
    cursor: pointer;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .icon-menu span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .icon-menu span:nth-child(1) {
    transform: translate(0, -10px);
  }
  .icon-menu span:nth-child(3) {
    transform: translate(0, 10px);
  }
  img {
    display: inline-block;
    max-width: 100%;
  }
}

#footer {
  width: 100%;
  background: #021828;
  color: #FFFFFF;
  overflow: hidden;
}

#footer .footer-content {
  max-width: 1140px;
  padding-top: 60px;
  box-sizing: border-box;
  margin: auto;
}

#footer .footer-content .logo-box {
  margin-bottom: 46px;
}

#footer .footer-content .content-info {
  display: flex;
}

#footer .footer-content .content-info .info {
  flex: 5;
  margin-right: 120px;
}

#footer .footer-content .content-info .info .title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 23px;
  display: inline-flex;
}

#footer .footer-content .content-info .info .title a {
  color: #FFFFFF;
  margin-left: 14px;
  text-decoration: none;
}

#footer .footer-content .content-info .info p {
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
}

#footer .footer-content .content-info .info p .iconfont {
  color: #F8A328;
  margin-right: 10px;
  font-size: 16px;
}

#footer .footer-content .content-info .info:nth-last-of-type(1) {
  margin-right: 0px;
  flex: 1;
}

#footer .copyright {
  margin-top: 60px;
  border-top: 1px solid #979797;
  padding: 42px 0 49px;
  font-size: 14px;
  text-align: center;
}

#footer .copyright a {
  text-decoration: none;
  color: #FFFFFF;
}

#popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

#popup .popup-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

#popup .popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

#popup .popup-content img {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

#popup .popup-content p {
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}

@media screen and (max-width: 992px) {
  #footer {
    padding: 0 20px;
    box-sizing: border-box;
  }
  #footer .footer-content .content-info {
    display: block;
    margin: auto;
  }
  #footer .footer-content .content-info .info {
    margin: 0 auto 20px;
  }
}

* {
  border: 0px;
  padding: 0px;
  margin: 0px;
}

ul, li {
  list-style: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: #106FDD;
}

.image-background {
  display: block;
  width: 100%;
}

#main,
#content {
  min-height: calc(100vh - 64px - 489px);
}

#index_content .layer-01 {
  padding: 70px 0 107px;
  background-image: url("./images/index_grey_bj.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  background-color: #F1F4F9;
}

#index_content .layer-01 .el-tabs__nav-wrap::after {
  background-color: transparent;
}

#index_content .layer-01 .el-tabs__nav {
  display: flex;
  width: 100%;
  align-items: center;
}

#index_content .layer-01 .el-tabs__nav .el-tabs__item {
  flex: 1;
  height: 80px;
  line-height: 80px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
}

#index_content .layer-01 .el-tabs__nav .el-tabs__item .ghy-tabs {
  text-align: center;
  background: #FFFFFF;
}

#index_content .layer-01 .el-tabs__nav .el-tabs__item:hover .ghy-tabs, #index_content .layer-01 .el-tabs__nav .el-tabs__item.is-active .ghy-tabs {
  background: #106FDD;
  color: #FFFFFF;
}

#index_content .layer-01 .el-tabs__nav .el-tabs__active-bar {
  background: transparent;
}

#index_content .layer-01 .el-tabs__content {
  margin-top: 50px;
  background: #FFFFFF;
  box-shadow: 10px 50px 59px 0px rgba(60, 78, 105, 0.1);
  padding: 40px 66px;
  box-sizing: border-box;
}

#index_content .layer-01 .el-tabs__content .ghy-content .content-title {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
  margin-top: 70px;
}

#index_content .layer-01 .el-tabs__content .ghy-content p {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-bottom: 50px;
  margin-top: 16px;
}

#index_content .layer-01 .el-tabs__content .ghy-content img {
  display: block;
}

#index_content .layer-01 .el-tabs__content .ghy-content a {
  display: block;
  width: 160px;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(157deg, #1FC6FC 0%, #116EDD 100%);
  border-radius: 24px;
  text-decoration: none;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  text-align: center;
}

#index_content .layer-02 {
  padding: 70px 0;
}

#index_content .layer-02 .content-box {
  width: 100%;
  height: 380px;
  background: #FFFFFF;
  margin-bottom: 32px;
  position: relative;
  border: 1px solid #E0E4E7;
}

#index_content .layer-02 .content-box .background-image {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  opacity: .2;
}

#index_content .layer-02 .content-box:hover {
  border-color: transparent;
  box-shadow: -10px 20px 40px 10px rgba(60, 78, 105, 0.1);
}

#index_content .layer-02 .content-box:hover .background-image {
  opacity: 1;
}

#index_content .layer-02 .content-box .content-info {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 24px;
  box-sizing: border-box;
}

#index_content .layer-02 .content-box .content-info .content-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
}

#index_content .layer-02 .content-box .content-info .content-title .iconfont {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  background: #106FDD;
  border-radius: 50%;
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 16px;
  font-weight: 400;
}

#index_content .layer-02 .content-box .content-info p {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #666666;
  margin-top: 20px;
  margin-bottom: 12px;
}

#index_content .layer-02 .content-box .content-info a {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #106FDD;
}

#index_content .layer-02 .content-box .content-info a span {
  margin-left: 6px;
}

#index_content .layer-03 {
  min-height: 420px;
  background-image: url("./images/index_blue_bj.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 72px;
}

#index_content .layer-03 .img-ico {
  display: block;
  margin: 0px auto 40px;
}

#index_content .layer-03 p {
  max-width: 959px;
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 36px;
  text-align: center;
  margin: auto;
}

#index_content .layer-04 {
  padding-top: 88px;
  padding-bottom: 42px;
}

.new-content {
  height: auto;
}

.new-content img {
  display: block;
  width: 100%;
  height: 160px;
}

.new-content .new-li {
  display: block;
  min-height: 310px;
  background: #FFFFFF;
  border: 1px solid #E0E4E7;
  text-decoration: none;
}

.new-content .new-li .content {
  padding: 14px;
  box-sizing: border-box;
  position: relative;
}

.new-content .new-li .content::after {
  display: block;
  content: '';
  width: 6px;
  height: 45px;
  background: #106FDD;
  position: absolute;
  left: 0px;
  top: 10px;
}

.new-content .new-li .content .new-title {
  height: 40px;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.new-content .new-li .content .new-time {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.new-content .new-li .content .btn {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #106FDD;
  padding: 0;
  height: auto;
}

.new-content .new-li:hover {
  border-color: transparent;
  box-shadow: -10px 20px 40px 10px rgba(60, 78, 105, 0.1);
}

.layer {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.ghy-content {
  max-width: 1140px;
  height: auto;
  margin: auto;
}

.title {
  font-size: 32px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

.title .line {
  width: 10px;
  height: 4px;
  background: #333333;
}

.title .line:nth-of-type(1) {
  margin-right: 10px;
}

.title .line:nth-last-child(1) {
  margin-left: 10px;
}

.title .black-line {
  background: #333333;
}

.title .white-line {
  background: #FFFFFF;
}

#new-info-content .synopsis {
  width: 100%;
  height: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: darken;
}

#new-info-content .synopsis .new-title {
  font-size: 40px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 56px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#new-info-content .content-info {
  min-height: calc(100vh - 64px - 489px);
  height: auto;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 70px;
  padding: 70px 0px 70px 24px;
  box-sizing: border-box;
  margin-top: -100px;
}

#new-info-content .content-info h1, #new-info-content .content-info h2, #new-info-content .content-info h3, #new-info-content .content-info h4, #new-info-content .content-info h5, #new-info-content .content-info h6,
#new-info-content .content-info div, #new-info-content .content-info p {
  line-height: 26px;
}

#new-info-content .content-info img {
  display: inline-block;
  width: 100%;
}

#new-info-content .content-info .new-list {
  border-left: 1px solid #F2F3F5;
}

#new-info-content .content-info .new-list .new-list-title {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 40px;
}

#new-info-content .content-info .new-list .new-li {
  margin-bottom: 20px;
}

#new-info-content .content-info .new-list .new-li a {
  display: flex;
  text-decoration: none;
}

#new-info-content .content-info .new-list .new-li a .img-ico {
  display: block;
  width: 100px;
  height: 56px;
  margin-right: 20px;
}

#new-info-content .content-info .new-list .new-li a .content {
  flex: 1;
}

#new-info-content .content-info .new-list .new-li a .content .new-li-title {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
}

#new-info-content .content-info .new-list .new-li a .content .new-li-time {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #999999;
}

#new-info-content .content-info .new-list .new-li:nth-last-child(1) {
  margin-bottom: 80px;
}

.sc-pagination {
  margin: auto;
  display: flex;
  justify-content: center;
}

.sc-pagination .sc-pagination__item,
.sc-pagination .sc-pagination__btn {
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  height: 32px;
  min-width: 32px;
  line-height: 30px;
  text-decoration: none;
  color: #333;
  text-align: center;
  white-space: nowrap;
}

.sc-pagination .sc-pagination__container {
  position: absolute;
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-left: 14px;
  margin-right: 14px;
}

.sc-pagination .sc-pagination__container .sc-pagination__item + .sc-pagination__item {
  margin-left: 14px;
}

.sc-pagination .sc-pagination__container .sc-pagination__item {
  padding: 0px 10px;
}

.sc-pagination .sc-pagination__container .sc-pagination__btn {
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}

.sc-pagination .sc-pagination__container .sc-pagination__item.active {
  border-color: #106FDD;
  color: #106FDD;
}

#news-center .banner {
  position: relative;
}

#news-center .banner .image-background {
  display: block;
}

#news-center .banner .content-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#news-center .banner .content-box .sub-title {
  font-size: 32px;
  font-family: NotoSansHans-Bold, NotoSansHans;
  font-weight: bold;
  color: #FFFFFF;
}

#news-center .banner .content-box .line {
  width: 48px;
  height: 4px;
  background: #32C5FF;
  border-radius: 2px;
  margin: auto;
}

#news-center main {
  background: #F1F4F9;
  padding: 70px 0 124px;
  box-sizing: border-box;
}

@media all and (max-width: 992px) {
  #index_content .layer-01 .ghy-content .el-tabs__nav {
    display: block;
  }
  #index_content .layer-01 .ghy-content .el-tabs__nav .el-tabs__item {
    padding: 0px;
    width: 50%;
    float: left;
    margin-top: 20px;
  }
  #index_content .layer-01 .ghy-content .el-tabs__nav .el-tabs__item .ghy-tabs {
    width: 85%;
    margin: auto;
  }
  #index_content .layer-01 .ghy-content .el-image {
    margin-top: 10px;
  }
  #index_content .layer-01 .ghy-content .el-tabs__content {
    padding: 20px;
  }
  #index_content .layer-01 .ghy-content .el-tabs__content .content-title {
    margin-top: 0;
    font-size: 16px;
  }
  #index_content .layer-01 .ghy-content .el-tabs__content .ghy-content a {
    width: 110px;
    height: 32px;
    line-height: 32px;
  }
  #index_content .layer-02 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  #index_content .layer-02 .content-box .content-info .content-title {
    font-size: 16px;
  }
  #index_content .layer-03 p {
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  #index_content .layer-04 {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  #index_content .layer-04 .new-content {
    min-height: 250px;
  }
  #index_content .layer-04 .new-content img {
    width: 100%;
    height: 200px;
  }
  .title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .ghy-content {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
